import FormGraphQLAutocomplete from '@/components/form/fields/formGraphQLAutocomplete';
import { FormGraphQLAutoCompleteProps } from '@/components/form/fields/types';
import { StaffsRead } from '@/data/management/staff.graphql';
import type { QueryStaffsReadArgs, Staff } from '@/types/schema';
import { Chip, Divider, MenuItem, Stack, Typography } from '@mui/material';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

export default function StaffSelect( props: Omit<FormGraphQLAutoCompleteProps<Staff, QueryStaffsReadArgs>, 'query' | 'queryKey'> ) {
	const { t } = useTranslation();
	return (
		<FormGraphQLAutocomplete<Staff>
			queryKey={[ 'staff' ]}
			query={StaffsRead}
			getOptionLabel={( { email, user }: Staff ) => [
				`${user?.firstName || ''} ${user?.lastName || ''}`.trim(), email ]
				.filter( Boolean )
				.join( ' - ' )}
			renderOption={( props, option ) => {
				const isAdmin = option.permissions.includes( 'ADMIN' );
				const isOwner = option.permissions.includes( 'OWNER' );
				return (
					<Fragment key={option.email ?? ''}>
						{option.user?.firstName && (
							<Fragment>
								<MenuItem key={option.id ?? ''} {...props}>
									<Stack>
										<Stack direction='row' alignItems='center' spacing={1}>
											<Typography>
												{option.user.firstName} {option.user?.lastName || ''}
											</Typography>
											<Chip
												label={isAdmin || isOwner ? option.permissions[ 0 ] : 'Staff'}
												color={isAdmin ? 'primary' : isOwner ? 'error' : 'default'}
												variant='alpha'
											/>
										</Stack>
										{option.email && (
											<Typography color='text.secondary'>
												{option.email}
											</Typography>
										)}
									</Stack>
								</MenuItem>
								<Divider sx={{ my: 1 }}/>
							</Fragment>
						)}
					</Fragment>
				);
			}}
			onAdd={undefined}
			{...props}
			textFieldProps={{ label: props.label || t( 'management:staff' ), ...props.textFieldProps }}
		/>
	);
}
