import Form from '@/components/form';
import FormPhoneField from '@/components/form/fields/phoneField';
import FormTextField from '@/components/form/fields/textField';
import { ModalFormWrapper } from '@/components/form/modal';
import PageLinkComponent from '@/components/page/linkComponent';
import { isEstimateType, isInvoiceType } from '@/helpers/useIsEstimateType';
import usePermissions from '@/providers/auth/usePermissions';
import useUserInfo from '@/providers/auth/useUserInfo';
import type { Order } from '@/types/schema';
import { Edit as EditIcon } from '@mui/icons-material';
import { IconButton, Stack, Tooltip } from '@mui/material';
import axios from 'axios';
import { startCase, toLower } from 'lodash-es';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

export default function TextMessage( { order, onSubmit }: { order: Order, onSubmit?: () => void } ) {
	const { staff } = useUserInfo();
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation();
	const isOwner = usePermissions( [ 'OWNER' ] );
	
	const clientName = order.client?.contact || order.client?.name || 'there';
	if ( clientName === '-' ) {
		clientName.replace( '-', 'there' );
	}
	const defaultMessage = `Hi ${clientName}\n`
		+ `You have an ${toLower( order.type ) || 'Order'} from ${order.company?.name || order.company?.contact}\n`;
	
	const customInvoiceMessage = staff?.company.templateData?.invoiceTextBody?.replace( /{clientname}/gi, clientName ) || defaultMessage;
	const customEstimateMessage = staff?.company.templateData?.estimateTextBody?.replace( /{clientname}/gi, clientName ) || defaultMessage;
	const customOrderMessage = staff?.company.templateData?.orderTextBody?.replace( /{clientname}/gi, clientName ) || defaultMessage;
	
	const messageTemplate = order.status === 'PAID' ? defaultMessage : ( isEstimateType( order.type )
			? customEstimateMessage
			: isInvoiceType( order.type ) ? customInvoiceMessage : customOrderMessage ) + '\n'
		+ `${process.env.NEXT_PUBLIC_SITE_URL}/p/${order.id}/${isEstimateType( order.type ) ? 'estimate'
			: isInvoiceType( order.type ) ? 'invoice' : toLower( order.type )}`;
	
	return (
		<Form
			initialValues={{
				message: messageTemplate,
				phone  : order.client?.cell || '',
			}}
			onSubmit={async ( values ) => {
				try {
					await axios.post( '/api/message', {
						company: staff?.company.id,
						number : values.phone,
						message: values.message,
					} );
					onSubmit?.();
					enqueueSnackbar( t( 'common:text-sent' ), { variant: 'success' } );
				} catch ( e ) {
					console.log( e );
					if ( !values.phone ) {
						enqueueSnackbar( t( 'common:add-cell' ), { variant: 'default' } );
						return;
					}
					enqueueSnackbar( t( 'common:text-not-sent' ), { variant: 'error' } );
					return;
				}
			}}>
			{( formik ) => (
				<ModalFormWrapper
					title={order
						? `${startCase( toLower( order.type ) )}#: ${order.metadata?.customNumber || order?.number || order?.externalId}`
						: t( 'common:send-text' )}
					secondaryTitle={order
						? `${t( 'common:send-sent-pre' )} ${startCase( toLower( order.type ) )} ${t( 'common:send-sent-post' )} `
						: t( 'common:send-friend' )}
					saveButtonProps={{
						disabled: !formik.values.phone || !formik.values.message,
					}}
					saveButtonText='Share'>
					<Stack spacing={2}>
						<FormPhoneField
							name='phone'
							placeholder={t( 'common:phone-number' )}
							onlyCountries={[ 'us', 'ca' ]}
							onChange={( value, data, event, formattedValue ) => {
								formik.setFieldValue( 'phone', formattedValue );
							}}
						/>
						<FormTextField
							fullWidth
							multiline
							name='message'
							placeholder={t( 'common:type-here' )}
							variant='outlined'
							inputProps={{
								maxLength: 180,
								mt       : 2,
							}}
							InputProps={{
								endAdornment: isOwner && (
									<Tooltip title='You can edit the default message body in the settings page'>
										<IconButton
											sx={{ alignSelf: 'flex-start', mt: 1 }}
											component={PageLinkComponent}
											href='/dashboard/settings/doc?tab=customization'
											target='_blank'>
											<EditIcon fontSize='small'/>
										</IconButton>
									</Tooltip>
								),
							}}
							rows={5}
						/>
					</Stack>
				</ModalFormWrapper>
			)}
		</Form>
	);
}
